'use client';
import * as Sentry from '@sentry/nextjs';
import React, { useEffect } from 'react';

import Error403 from '@/components/app/error/403';
import Error404 from '@/components/app/error/404';
import Error429 from '@/components/app/error/429';
import Error500 from '@/components/app/error/500';

function Error({ statusCode }) {
  const errorPages = {
    404: <Error404 />,
    403: <Error403 />,
    500: <Error500 />,
    503: <Error429 />,
  };
  useEffect(() => {
    if (!errorPages[statusCode]) {
      Sentry.captureException(`Unhandled error with status code: ${statusCode}`);
    }
  }, [statusCode]);

  return errorPages[statusCode] || errorPages[500];
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  if (err) {
    Sentry.captureException(err);
  }

  return { statusCode };
};

export default Error;
