import Link from 'next/link';

import cn from '@/lib/clsxm';

import XImage from '@/components/@base/x-image';
import Props, { LOGO_SIZE } from '@/components/shared/components/logo/type';

import imagesList from '@/constant/images-list';
import localization from '@/constant/localization';

export default function ZhkLogo({ darkMode = false, size = LOGO_SIZE.MD }: Props) {
  return (
    <Link href='/' prefetch={false}>
      <XImage
        src={darkMode ? imagesList.icons.zhkDarkLogo : imagesList.icons.zhkLightLogo}
        alt={localization.zhaket}
        className={cn(
          size === LOGO_SIZE.SM && 'min-h-[31px] min-w-[44px]',
          size === LOGO_SIZE.MD && 'min-h-[39px] min-w-[55px]',
          size === LOGO_SIZE.LG && 'min-h-[43px] min-w-[60px]',
          size === LOGO_SIZE.XL && 'min-h-[82px] min-w-[114px]',
        )}
        width={60}
        height={43}
      />
    </Link>
  );
}
