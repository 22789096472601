import Props, { LOADING_COLORS } from '@/components/@base/loading/type';
import XImage from '@/components/@base/x-image';

import imagesList from '@/constant/images-list';
import localization from '@/constant/localization';

export default function Loading({ color = LOADING_COLORS.ORANGE, className }: Props) {
  return (
    <XImage
      src={imagesList.icons[`${color}Loading`]}
      className={className}
      alt={localization.zhaket}
      width={50}
    />
  );
}
