import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Heading, Text } from '@/components/@base/typography';
import { HEADING_TYPE } from '@/components/@base/typography/heading/type';
import Background from '@/components/shared/components/background';
import ZhkLogo from '@/components/shared/components/logo';
import { LOGO_SIZE } from '@/components/shared/components/logo/type';

import imagesList from '@/constant/images-list';
import { pageLevelLocalization } from '@/constant/localization';

const { error: errorLocalization, layout: layoutLocalization } = pageLevelLocalization;

export default function Error() {
  return (
    <Container
      center
      className='error-page-bg-mobile h-screen w-screen flex-col gap-2 text-[#1F2025]'
    >
      <Background
        src={imagesList.images.error429}
        className='z-10 m-auto mt-[25vh] !h-[330px] !w-[600px] object-contain md:object-cover'
      />
      <Container center className='z-20 flex-col'>
        <ZhkLogo darkMode size={LOGO_SIZE.XL} />
        <Heading type={HEADING_TYPE.H1} className='mt-7 text-3xl font-bold text-center' >
          {errorLocalization.message429}
        </Heading>
        <Text className='mb-[18px] text-[#424244]' size={SIZE_ENUM.SM} >
          {errorLocalization.message429_try_again}
        </Text>
        <a href='/'>
          <Container
            center
            className='h-[55px] w-[186px] rounded-lg bg-[#FFAE11] text-base font-bold text-[#FFFFFF] transition duration-300 hover:bg-[#EB8800]'
          >
            {errorLocalization.returnHome}
          </Container>
        </a>
      </Container>
    </Container>
  );
}
