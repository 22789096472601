export enum LOGO_SIZE {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

type Props = {
  size?: LOGO_SIZE;
  darkMode?: boolean;
};
export default Props;
