import Base from "@/components/@base/@helpers/types";

export enum LOADING_COLORS {
    WHITE = 'white',
    ORANGE = 'orange',
}

type Props = {
    color?: LOADING_COLORS;
} & Pick<Base, 'className'>;
export default Props;
