import cn from '@/lib/clsxm';

import XImage from '@/components/@base/x-image';

import localization from '@/constant/localization';

import Props from './type';

export default function Background({ src, className }: Props) {
  return (
    <XImage
      src={src}
      fill
      alt={localization.zhaket}
      className={cn(
        'absolute right-0 top-0 -z-10 w-full overflow-hidden bg-repeat-round object-cover',
        className,
      )}
    />
  );
}
