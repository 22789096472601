import cn from '@/lib/clsxm';

import { COLOR_ENUM, SIZE_ENUM } from '@/components/@base/@helpers/types';
import { BUTTON_TYPE, Props, VARIANT_ENUM } from '@/components/@base/button/type';
import Container from '@/components/@base/container';
import Loading from '@/components/@base/loading';
import { LOADING_COLORS } from '@/components/@base/loading/type';

export default function Button({
  children,
  color,
  className,
  size = SIZE_ENUM.SM,
  variant = VARIANT_ENUM.DEFAULT,
  disabled = false,
  isLoading = false,
  loadingColor = LOADING_COLORS.WHITE,
  type = BUTTON_TYPE.BUTTON,
  Icon,
  ...props
}: Props) {
  return (
    <button
      {...props}
      disabled={disabled}
      className={cn(
        'font-semibold flex items-center justify-center gap-[10px] rounded-lg text-white transition duration-300 focus:outline-none focus:outline-0',
        size === SIZE_ENUM.LG && 'text-md h-13 px-7 py-4',
        size === SIZE_ENUM.MD && 'h-12 px-5 py-4 text-sm',
        size === SIZE_ENUM.SM && 'h-10 px-4 py-3 text-xs',
        variant === VARIANT_ENUM.OUTLINED &&
          'border-secondary text-secondary hover:bg-secondary border hover:text-white',
        variant === VARIANT_ENUM.DEFAULT && 'hover:bg-secondary/80 bg-[#FFAE11] ',
        variant === VARIANT_ENUM.DARK_ORANGE &&
          'hover:bg-secondary/80 bg-[#FF9606] hover:bg-[#FFE6C0] hover:text-[#B47510]',
        variant === VARIANT_ENUM.LIGHT_ORANGE && 'hover:bg-secondary/80 bg-[#FFB616]',
        color === COLOR_ENUM.PRIMARY &&
          variant === VARIANT_ENUM.DEFAULT &&
          'bg-primary hover:bg-primary/80',
        color === COLOR_ENUM.PRIMARY &&
          variant === VARIANT_ENUM.OUTLINED &&
          'border-primary text-primary hover:bg-primary',
        variant === VARIANT_ENUM.GREY && 'bg-[#EAEEF380] text-[#7E899B]',
        variant === VARIANT_ENUM.SOUL &&
          'border border-[#E5E8EB] bg-white text-[#787676] shadow-[0px_2px_6px_0px_rgba(126,137,155,0.08)] hover:bg-[#76767c] hover:text-white',
        disabled && 'cursor-not-allowed !bg-[#E5E8EB] !text-[#878F9B] hover:text-white',
        disabled && VARIANT_ENUM.DEFAULT && 'bg-i-gray hover:bg-i-gray/50',

        isLoading && 'pointer-events-none cursor-not-allowed',
        className,
      )}
      type={type}
    >
      {Icon && Icon}
      <Container center>{isLoading ? <Loading color={loadingColor} /> : children}</Container>
    </button>
  );
}
