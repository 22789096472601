import { ButtonHTMLAttributes, ReactNode } from 'react';

import Base, { COLOR_ENUM, SIZE_ENUM } from '@/components/@base/@helpers/types';
import { LOADING_COLORS } from '@/components/@base/loading/type';

export enum BUTTON_TYPE {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum VARIANT_ENUM {
  DEFAULT = 'default',
  OUTLINED = 'outlined',
  GREY = 'grey',
  SOUL = 'soul',
  DARK_ORANGE = 'darkOrange',
  LIGHT_ORANGE = 'lightOrange',
}

export interface Props extends Base, ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  disabled?: boolean;
  type?: BUTTON_TYPE;
  size?: SIZE_ENUM;
  variant?: VARIANT_ENUM;
  color?: COLOR_ENUM;
  Icon?: ReactNode;
  loadingColor?: LOADING_COLORS;
}
